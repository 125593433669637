import { useRouter } from "next/router";

import * as routes from "routes";
import UserContext from "context/UserContext";
import { Avatar, Menu, MenuItem, Divider } from "element";
import { useContext, useRef, useState } from "react";
import { UserType } from "types";

export default function AccountMenu() {
  const [open, setOpen] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const { user, logout } = userContext;
  const router = useRouter();
  const avatarRef = useRef(null);

  let loggedInItems = null;

  if (user) {
    loggedInItems = [];
    loggedInItems.push(
      <MenuItem
        onClick={() => {
          setOpen(false);
          router.push(routes.accountSettingsPath());
        }}
        key="account-settings"
      >
        Account Settings
      </MenuItem>
    );

    loggedInItems.push(<Divider key="divider" />);
    loggedInItems.push(
      <MenuItem
        onClick={async () => {
          setOpen(false);
          await logout();
          if (user.user_type === UserType.insured) {
            router.push(routes.loginPath({ client_id: user.client_id }));
          } else {
            router.push("/");
          }
        }}
        key="logout"
      >
        Log Out
      </MenuItem>
    );
  }

  const menu = (
    <Menu
      open={open}
      onClose={() => setOpen(false)}
      anchorEl={avatarRef.current}
    >
      {loggedInItems}
    </Menu>
  );

  const avatar = user ? (
    <Avatar onClick={() => setOpen(true)} ref={avatarRef} alt={user.email}>
      {user.first_name && user.first_name[0]}
      {user.last_name && user.last_name[0]}
    </Avatar>
  ) : (
    <Avatar onClick={() => setOpen(true)} ref={avatarRef}></Avatar>
  );

  return (
    <div>
      {avatar}
      {menu}
    </div>
  );
}
