import Logo from "component/Logo";
import AccountMenu from "component/AccountMenu";
import { Link, Toolbar, AppBar, GridContainer, GridItem, Box } from "element";

import MenuIcon from "@mui/icons-material/Menu";
import { useRouter } from "next/router";

import { useCallback, useContext } from "react";
import UserContext from "context/UserContext";
import { Client, Maybe } from "types";

interface AppNavProps {
  setOpen?: (open: boolean) => void;
  endInsuredClient?: Client;
  // optionally specify a custom url for top-left logo click
  homeUrl?: Maybe<string>;
  disableAccountMenu?: boolean;
  disabled?: boolean;
}

export default function AppNav({
  setOpen,
  endInsuredClient,
  homeUrl,
  disableAccountMenu,
  disabled,
}: AppNavProps) {
  const { user } = useContext(UserContext);
  const router = useRouter();

  const navigateToCustomUrl = useCallback(() => {
    if (homeUrl) {
      router.push(homeUrl);
    } else {
      router.push("/");
    }
  }, [homeUrl, router]);

  return (
    <AppBar position="static" sx={{ justifyContent: "center" }}>
      <Toolbar
        sx={{ justifyContent: "space-between", pl: ["54px"], pr: ["54px"] }}
      >
        {!disableAccountMenu && (
          <MenuIcon
            sx={{
              display: { lg: "none" },
              color: "black",
              marginRight: "20px",
            }}
            onClick={() => setOpen && setOpen(true)}
          />
        )}

        <Link disabled={disabled} href="/" onClick={navigateToCustomUrl}>
          <Logo endInsuredClient={endInsuredClient} />
        </Link>
        <GridContainer justifyContent="flex-end" alignItems="center">
          {user && (
            <>
              <GridItem>{!disableAccountMenu && <AccountMenu />}</GridItem>
            </>
          )}
        </GridContainer>
      </Toolbar>
      {user && !endInsuredClient && (
        <Box sx={{ color: "text.secondary", pl: ["98px"] }}>
          {user.client?.name}
        </Box>
      )}
    </AppBar>
  );
}
