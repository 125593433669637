import { Box } from "@mui/system";
import { ReactNode, useEffect, useState } from "react";

import { styled } from "@mui/material/styles";

import { BORDER_RADIUS } from "theme";
import { GridContainer, GridItem, H3 } from "element";

import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface AppBarNotificationProps {
  success?: boolean;
  error?: boolean;
  onHide?: () => void;
  children?: ReactNode;
}

const StyledBox = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.background.paper};
  padding: ${theme.spacing(2)};
  border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
  box-shadow: ${theme.shadows[8]};
  text-align: center;
  position: fixed;
  top: 0; left: ${theme.spacing(2)}; right: ${theme.spacing(2)};
  z-index: ${theme.zIndex.modal}
`
);

export default function AppBarNotification({
  success,
  error,
  onHide,
  children,
}: AppBarNotificationProps) {
  const [content, setContent] = useState<ReactNode>(null);

  useEffect(() => {
    let nodeToRender = children;
    if (success) {
      nodeToRender = (
        <GridContainer justifyContent="center" alignItems="center" spacing={1}>
          <GridItem>
            <CheckCircleOutlineOutlinedIcon
              color="success"
              sx={{ verticalAlign: "bottom" }}
            />
          </GridItem>
          <GridItem>
            <H3>{children}</H3>
          </GridItem>
        </GridContainer>
      );
      setTimeout(() => {
        onHide && onHide();
      }, 8000);
    } else if (error) {
      nodeToRender = (
        <GridContainer justifyContent="center" alignItems="center" spacing={1}>
          <GridItem>
            <ErrorOutlineIcon color="error" sx={{ verticalAlign: "bottom" }} />
          </GridItem>
          <GridItem>
            <H3>{children}</H3>
          </GridItem>
        </GridContainer>
      );
      setTimeout(() => {
        onHide && onHide();
      }, 8000);
    }
    setContent(nodeToRender);
  }, [children, error, onHide, success]);

  return content ? <StyledBox>{content}</StyledBox> : null;
}
